import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailsPanierComponent } from '../details-panier/details-panier.component';
import { InscriptionComponent } from '../inscription/inscription.component';
import { ListLoginComponent } from '../login/list-login/list-login.component';

@Component({
  selector: 'app-home',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {
  constructor(public api : ApiService,private modalService : NgbModal){
    api.update_data_from_token()
  }
  ngOnInit(): void {
  }
  openModal_se_connecter() {
    let options: any = {
      centered: true,
      scrollable: true,
      size: "xl"//'sm' | 'lg' | 'xl' | string
    }
    const modalRef = this.modalService.open(ListLoginComponent, { ...options, backdrop: 'static' })
    modalRef.result.then((result: any) => {
      console.log('Modal closed with:', result);
      if (result?.status) {
      } else {

      }
    })
  }
  openModal_inscription() {
    let options: any = {
      centered: true,
      scrollable: true,
      size: "lg"//'sm' | 'lg' | 'xl' | string
    }
    const modalRef = this.modalService.open(InscriptionComponent, { ...options, backdrop: 'static' })
    modalRef.result.then((result: any) => {
      console.log('Modal closed with:', result);
      if (result?.status) {
      } else {

      }
    })
  }
  openModal_details_panier() {
    let options: any = {
      centered: true,
      scrollable: true,
      size: "xl"//'sm' | 'lg' | 'xl' | string
    }
    const modalRef = this.modalService.open(DetailsPanierComponent, { ...options, backdrop: 'static' })
    modalRef.result.then((result: any) => {
      console.log('Modal closed with:', result);
      if (result?.status) {
      } else {

      }
    })
  }
  postPanier(produit: any) {
    this.api.postToPanier(produit);
    this.api.nombreProduitPanier = JSON.parse(localStorage.getItem('panier') ?? '[]').length;

  }

  
}

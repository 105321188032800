<div class="modal-header" data-bs-theme="light">
  <h1 class="modal-title fs-5">Connexion</h1>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <div class="col-md-5 d-none d-md-block" style="height: 500px;">
              <img src="assets/login.jpeg" alt="login form" class="img-fluid h-100"
                style="border-radius: 1rem 0 0 1rem; object-fit: cover;" />
            </div>
            <div class="col-md-7 d-flex align-items-center">
              <div class="card-body p-4 text-black">
                <form [formGroup]="reactiveForm_login_login" (ngSubmit)="onSubmit_login_login()"
                  #form_login_login="ngForm">
                  <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">
                    Veuillez renseigner vos informations de connexion
                  </h5>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example17">Identifiant (email ou téléphone)</label>
                    <input class="form-control form-control-lg" type="text" formControlName="login" placeholder="xxxx"
                      [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />
                    <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                      <div *ngIf="f.login.errors.required">login est obligatoire</div>
                    </div>
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example27">Mot de passe</label>
                    <div class="input-group">
                      <input class="form-control" type="password" formControlName="pwd" placeholder="....."
                        [ngClass]="{ 'is-invalid': submitted && f.pwd.errors }" id="passwordInput" />
                      <button class="btn btn-outline-secondary" type="button" (click)="showPassword()">
                        <i class="bx bx-low-vision"></i>
                      </button>
                    </div>
                    <div *ngIf="submitted && f.pwd.errors" class="invalid-feedback">
                      <div *ngIf="f.pwd.errors.required">pwd est obligatoire</div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="loading_login_login"
    (click)="form_login_login.ngSubmit.emit()">
    {{ loading_login_login ? "En cours ..." : "Me Connecter" }}
  </button>
  <button type="reset" class="btn btn-secondary me-2" (click)="onReset_login_login()">Vider</button>
  <button type="button" class="btn btn-outline-danger" (click)="activeModal.close()">Fermer</button>
</div>
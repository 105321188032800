<div class="container mt-5">
  <div class="row panier-container">
    <div class="section-title">
      <h4>Panier ({{nombreArticles}})</h4>
      <hr />
    </div>
    <!-- Left Section: Cart Items -->
    <div class="panier-left p-2 col-lg-8 col-md-12">
      <div class="panier-items">
        <!-- Loop through cart items -->
        <div class="panier-item d-flex align-items-center justify-content-between mb-3 p-3 bg-white shadow-sm rounded"
          *ngFor="let item of panier;">
          <img [src]="api.files_base_url + item.produit.les_images[0].image_url" alt="{{item.produit.nom_produit}}"
            class="img-fluid me-3" style="width: 80px; height: auto;" />
          <div class="nom_prix flex-grow-1">
            <p class="m-0 fw-bold fs-3">{{item.produit.nom_produit| slice:0:17 }}</p>
            <div class="d-flex align-items-center">
              <span class="text-danger me-2">{{item.produit.prix |number}} Fcfa</span> / Unité
            </div>
          </div>

          <div class="text-end me-3">
            <p class="m-0 fw-bold"> <span class="fs-2">{{item.produit.prix * item.quantitePanier | number}}</span> Fcfa
            </p>
          </div>
          <div class="quantite d-flex align-items-center justify-content-center">
            <button class="btn btn-light p-0 fs-3"
              style="width: 30px; height: 30px; border-radius: 50%; display: flex; align-items: center; justify-content: center;"
              (click)="upOrDownQuantity('down', item.produit.id_produit)">-</button>
            <input readonly [(ngModel)]="item.quantitePanier" class="mx-2 text-center form-control"
              style="width: 50px; height: 30px; border-radius: 5px;" />
            <button class="btn btn-light p-0 fs-3"
              style="width: 30px; height: 30px; border-radius: 50%; display: flex; align-items: center; justify-content: center;"
              (click)="upOrDownQuantity('up', item.produit.id_produit)">+</button>
          </div>

          <div class="text-center ms-3">
            <i class="bx bx-trash text-danger cursor-pointer fs-5"
              (click)="deleteFromPanier(item.produit.id_produit)"></i>
          </div>
        </div>
        <div class="my-3 py-5 text-center">
          <button class="btn btn-outline-primary mt-3" [routerLink]="['/public']">Continuer mes achats</button>
        </div>
      </div>
    </div>

    <!-- Right Section: Recap -->
    <div class="panier-right col-lg-4 col-md-12 mt-2">
      <div class="recapitulatif p-3 mb-4 bg-light rounded">
        <h6 class="text-center pt-3 fw-bold">RÉCAPITULATIF</h6>
        <hr />
        <div class="d-flex flex-column justify-content-between p-lg-3" style="height: 150px">
          <div class="d-flex justify-content-between w-100">
            <span>{{nombreArticles}} Articles</span>
            <span class="fs-4">{{sommeArticles | number}}</span>
          </div>
          <div class="d-flex justify-content-between w-100">
            <span>Livraison</span>
            <span>Calculée à la caisse</span>
          </div>
          <hr />
          <div class="d-flex justify-content-between w-100">
            <span>Total</span>
            <div>
              <span class="fs-2">{{sommeArticles | number}}</span> Fcfa
            </div>
          </div>
        </div>
      </div>

      <!-- New Section: Address Input -->
      <div class="address-input p-3 mb-4 bg-light rounded">
        <h6 class="text-center pt-3 fw-bold">INFORMATIONS</h6>
        <hr />
        <label class="" for="">Adresse de Livraison : </label>
        <input type="text" [(ngModel)]="adresseLivraison" class="form-control" placeholder="Dakar, Ouest Foire"
          required />
        <label class="" for="">Numéro de telephone : </label>
        <input type="text" [(ngModel)]="numero_telephone" class="form-control" placeholder="XXXXXXXXXXXX" required />
        <button class="btn btn-primary w-100 mt-3" [disabled]="panier.length == 0"
          (click)="verifier_connexion()">Valider la commande</button>
      </div>

      <!-- <div class="nb p-3 bg-warning text-white rounded">
        <h6 class="text-center pt-3 fw-bold">
          <i class="bi bi-info-square" style="font-size: 16px;"></i> NB
        </h6>
        <hr />
        <p class="m-0">Veuillez bien préciser l’adresse de livraison.</p>
        <p class="m-0">Livraison en moins de 3H.</p>
        <p class="m-0">Uniquement à Dakar.</p>
      </div> -->
    </div>
  </div>
</div>
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../service/api/api.service';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListLoginComponent } from '../login/list-login/list-login.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './inscription.component.html',
  styleUrl: './inscription.component.css'
})
export class InscriptionComponent {
  meme_login: any[] = []
  reactiveForm_add_utilisateur_email !: FormGroup;
  reactiveForm_add_utilisateur_code_verification !: FormGroup;
  reactiveForm_add_utilisateur_fin_login !: FormGroup;
  submitted: boolean = false
  mail_retour: any
  loading_add_utilisateur: boolean = false
  loading_envoie_mail: boolean = false
  form_details: any = {}
  step: any = 1;
  error: any
  submitted_email: boolean = false;
  email_verification: any
  submitted_code_verification: boolean = false;
  submitted_fin_login: boolean = false;
  loading_get_details_add_utilisateur_form = false
  constructor(private formBuilder: FormBuilder, public api: ApiService, public activalModal: NgbActiveModal, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    this.get_details_add_utilisateur_form()
    this.init_form()
  }
  init_form() {
    this.reactiveForm_add_utilisateur_email = this.formBuilder.group({
      email: ["", Validators.required],
      id_role: ["", Validators.required],
    });

    this.reactiveForm_add_utilisateur_code_verification = this.formBuilder.group({
      code: ["", Validators.required],
    });

    this.reactiveForm_add_utilisateur_fin_login = this.formBuilder.group({
      nom_utilisateur: ["", Validators.required],
      prenom_utilisateur: ["", Validators.required],
      mot_de_passe: ["", Validators.required],
      adresse: ["", Validators.required],
      telephone: ["", Validators.required]
    });
  }

  // acces facile au champs de votre formulaire
  get f1(): any { return this.reactiveForm_add_utilisateur_email.controls; }
  get f2(): any { return this.reactiveForm_add_utilisateur_code_verification.controls; }
  get f3(): any { return this.reactiveForm_add_utilisateur_fin_login.controls; }

  // validation du formulaire
  onSubmit_add_utilisateur() {
    this.submitted_email = true;
    this.submitted_code_verification = true;
    this.submitted_fin_login = true;
    this.loading_add_utilisateur = true;

    console.log(this.reactiveForm_add_utilisateur_email.value)
    console.log(this.reactiveForm_add_utilisateur_code_verification.value)
    console.log(this.reactiveForm_add_utilisateur_fin_login.value)

    let email = this.reactiveForm_add_utilisateur_email.value;
    let fin_login = this.reactiveForm_add_utilisateur_fin_login.value;
    let utilisateurs = Object.assign({}, email, fin_login);
    utilisateurs.etat = 0// activer le compte par défaut
    // if (utilisateurs.id_role == 2) {
    // } else {
    //   utilisateurs.etat = 0
    // }
    console.log('utilisateurs', utilisateurs);

    // stop here if form is invalid
    if (this.reactiveForm_add_utilisateur_email.invalid) {
      return;
    }
    if (this.reactiveForm_add_utilisateur_code_verification.invalid) {
      return;
    }

    if (this.step == 3) {
      this.submitted_fin_login = true;
      if (this.reactiveForm_add_utilisateur_fin_login.invalid) {
        return;
      }
    }
    setTimeout(() => {
      this.add_utilisateur(utilisateurs)
    }, 1000)
  }
  // vider le formulaire
  onReset_add_utilisateur() {
    this.submitted_email = false;
    this.submitted_code_verification = false;
    this.submitted_fin_login = false;
    this.reactiveForm_add_utilisateur_email.reset();
    this.reactiveForm_add_utilisateur_code_verification.reset();
    this.reactiveForm_add_utilisateur_fin_login.reset();
  }
  add_utilisateur(utilisateur: any) {
    this.loading_add_utilisateur = true;
    this.api.taf_post("utilisateur/add", utilisateur, (reponse: any) => {
      if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table utilisateur. Réponse= ", reponse);
        this.api.save_on_local_storage("token", reponse.data)
        this.api.update_data_from_token()
        this.api.Swal_success("Inscription terminée")
        this.activalModal.close()

        this.router.navigate(["/home"])
      } else {
        console.log("L\'opération sur la table utilisateur a échoué. Réponse= ", reponse);
        this.api.Swal_error("L'opération a echoué")
      }
      this.loading_add_utilisateur = false;
    }, (error: any) => {
      this.loading_add_utilisateur = false;
    })
  }
  get_details_add_utilisateur_form() {
    this.loading_get_details_add_utilisateur_form = true;
    this.api.taf_post("utilisateur/get_form_details", {}, (reponse: any) => {
      if (reponse.status) {
        this.form_details = reponse.data
        console.log("Opération effectuée avec succés sur la table utilisateur. Réponse= ", reponse);
      } else {
        console.log("L'opération sur la table utilisateur a échoué. Réponse= ", reponse);
        this.api.Swal_error("L'opération a echoué")
      }
      this.loading_get_details_add_utilisateur_form = false;
    }, (error: any) => {
      this.loading_get_details_add_utilisateur_form = false;
    })
  }
  next() {
    this.email_verification = this.reactiveForm_add_utilisateur_email.value;
    // this.color_stats = 0
    console.log("email_verification", this.email_verification)
    if (this.step == 1) {
      this.submitted_email = true;
      if (this.reactiveForm_add_utilisateur_email.invalid) { return }
      this.envoie_email(this.email_verification);
      this.step++
    }
    if (this.step == 2) {
      if (this.calculateDifferenceInMinutes(this.mail_retour?.created_at) < 15) {
        this.calculateDifferenceInMinutes(this.mail_retour.created_at)
        if (this.reactiveForm_add_utilisateur_code_verification.invalid) {
          this.api.Swal_error("Format Code incorrect")
          return
        }
        if (this.email_verification.email == this.mail_retour.email && +this.reactiveForm_add_utilisateur_code_verification.value.code == +this.mail_retour.code) {
          this.submitted_code_verification = true;
          console.log('bon_code');
          this.step++;
        } else {
          console.log('mauvais code');
          this.error = "Code incorrect"
          this.api.Swal_error("Code incorrect")
        }
      } else {
        this.error = "Code erroné"
      }
    }
  }
  retour() {
    this.step = 1
  }
  openModal_se_connecter() {
    let options: any = {
      centered: true,
      scrollable: true,
      size: "xl"//'sm' | 'lg' | 'xl' | string
    }
    const modalRef = this.modalService.open(ListLoginComponent, { ...options, backdrop: 'static' })
    modalRef.result.then((result: any) => {
      console.log('Modal closed with:', result);
      if (result?.status) {
      } else {

      }
    })
  }
  envoie_email(email: any) {
    this.loading_envoie_mail = true;
    this.api.taf_post("utilisateur/envoie", email, (reponse: any) => {
      //when success
      if (reponse.status) {
        this.mail_retour = reponse.data
        console.log("Opération effectuée avec succés sur la table utilisateur. Réponse= ", reponse);
      } else {
        console.log("L\'opération sur la table utilisateur a échoué. Réponse= ", reponse);
      }
      this.loading_envoie_mail = false;
    },
      (error: any) => {
        //when error
        this.loading_envoie_mail = false;
        console.log("Erreur inconnue! ", error);
      })
  }
  calculateDifferenceInMinutes(createdAt: string): number {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();

    // Calculer la différence en millisecondes
    const differenceInMs = currentDate.getTime() - createdAtDate.getTime();

    // Convertir la différence en minutes
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
    console.log("differenceInMinutes", differenceInMinutes)
    return differenceInMinutes;
  }
  login_exist() {
    this.meme_login = this.form_details.les_emails.filter((un_agent: any) => {
      return un_agent.email == this.reactiveForm_add_utilisateur_email.value.email
    })
    console.log("login_exist= ", this.meme_login, " value= ", this.reactiveForm_add_utilisateur_email.value.email, ' meme_login.length= ', this.meme_login.length)
  }

}

<div class="modal-header" data-bs-theme="ligth">
    <h1 class="modal-title fs-5"> S'inscrire</h1>
    <button type="button" class="btn-close" aria-label="Close" (click)="activalModal.close()"></button>
</div>
<div class="modal-body fs-3">
    <!-- Form for User Email -->
    <form [formGroup]="reactiveForm_add_utilisateur_email" *ngIf="step === 1">
        <div class="form-group mb-4">
            <!-- <label for="" class="">Type de compte <span class="text-danger">*</span></label> -->
            <div class="d-flex">
                <div *ngFor="let one of form_details.les_roles" class="me-3">
                    <input [ngClass]="{ 'is-invalid': submitted_email && f1.id_role.errors }" required
                        formControlName="id_role" type="radio" class="form-check-input me-2" id="{{one.id_role}}"
                        [value]="one.id_role">
                    <label class="form-check-label" for="{{one.id_role}}">devenir <b>{{one.nom_role}}</b></label>
                </div>
                <div *ngIf="submitted_email && f1.id_role.errors" class="invalid-feedback">
                    <div *ngIf="f1.id_role.errors.required">
                        Ce champ est requis
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group mb-4">
            <label for="email" class="">Email <span class="text-danger">*</span></label>
            <input (ngModelChange)="login_exist()" type="email" id="email" formControlName="email"
                placeholder="xxxx@gmail.com" [ngClass]="{ 'is-invalid': submitted_email && f1.email.errors }" required
                class="form-control p-2 border border-gray-300 rounded">
            <span class="text-danger" *ngIf="meme_login.length>0">L'email existe deja</span>
            <div *ngIf="submitted_email && f1.email.errors" class="invalid-feedback">
                <div *ngIf="f1.email.errors.required">
                    Ce champ est requis
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-end">
            <button [disabled]="meme_login.length > 0" type="button" (click)="next()"
                class="btn btn-primary">Continuer</button>
        </div>
    </form>
    <!-- Code Verification -->
    <form [formGroup]="reactiveForm_add_utilisateur_code_verification" *ngIf="step === 2">
        <p class="text-lg"><b>Entrez le code à 4 chiffres pour confirmer qu'il s'agit bien de vous</b></p>
        <div class="form_code_v mt-4">
            <div class="otp-Form p-4 bg-light border rounded">
                <p class="otpSubheading mb-3">Nous avons envoyé un code de vérification à votre adresse e-mail.</p>
                <div class="form-group mb-3">
                    <label for="code" class="form-label">Code de vérification <span class="text-red">*</span> :</label>
                    <input type="number" id="code" formControlName="code" placeholder="Entrez le code ici"
                        [ngClass]="{ 'is-invalid': submitted_code_verification && f2.code.errors }" required
                        class="form-control p-2 border border-gray-300 rounded">
                    <div *ngIf="submitted_code_verification && f2.code.errors" class="invalid-feedback mt-2">
                        <div *ngIf="f2.code.errors.required">
                            <span class="error-icon">✖</span> Ce champ est requis
                        </div>
                    </div>
                </div>
                <div class="text-center text-danger mt-2">
                    <!-- <p class="resendNote">{{error}}</p> -->
                </div>

                <div class="resend_code d-flex justify-content-between mt-2">
                    <p class="resendNote">Vous n'avez pas reçu le code ?</p>
                    <button (click)="envoie_email(this.email_verification)" class="btn btn-link p-0 resendBtn"
                        type="button">
                        Renvoyer le code
                    </button>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <button type="button" (click)="retour()" class="btn btn-outline-secondary">Retour</button>
                    <button type="button" (click)="next()" class="btn btn-primary">Vérifier</button>
                </div>
            </div>
        </div>
    </form>
    <!-- Final Login Step -->
    <form [formGroup]="reactiveForm_add_utilisateur_fin_login" (ngSubmit)="onSubmit_add_utilisateur()"
        *ngIf="step === 3">
        <!-- champs prenom_utilisateur avec une gestion de la validité -->
        <div class="form-group col-sm-12">
            <label>Prénom <span class="text-red">*</span> : </label>
            <input class="form-control" type="text" formControlName="prenom_utilisateur" placeholder="Baraka"
                [ngClass]="{ 'is-invalid': submitted && f3.prenom_utilisateur.errors }" />
            <div *ngIf="submitted && f3.prenom_utilisateur.errors" class="invalid-feedback">
                <div *ngIf="f3.prenom_utilisateur.errors.required"> prénom est obligatoire </div>
            </div>
        </div>
        <!-- champs nom_utilisateur avec une gestion de la validité -->
        <div class="form-group col-sm-12">
            <label>Nom <span class="text-red">*</span> : </label>
            <input class="form-control" type="text" formControlName="nom_utilisateur" placeholder="compaore"
                [ngClass]="{ 'is-invalid': submitted && f3.nom_utilisateur.errors }" />
            <div *ngIf="submitted && f3.nom_utilisateur.errors" class="invalid-feedback">
                <div *ngIf="f3.nom_utilisateur.errors.required"> nom est obligatoire </div>
            </div>
        </div>
        <!-- champs adresse avec une gestion de la validité -->
        <div class="form-group col-sm-12">
            <label>Adresse <span class="text-red">*</span> : </label>
            <input class="form-control" type="text" formControlName="adresse" placeholder="Burkina"
                [ngClass]="{ 'is-invalid': submitted && f3.adresse.errors }" />
            <div *ngIf="submitted && f3.adresse.errors" class="invalid-feedback">
                <div *ngIf="f3.adresse.errors.required"> nom est obligatoire </div>
            </div>
        </div>
        <!-- champs telephone avec une gestion de la validité -->
        <div class="form-group col-sm-12">
            <label>Telephone <span class="text-red">*</span> : </label>
            <input class="form-control" type="text" formControlName="telephone" placeholder="775431245"
                [ngClass]="{ 'is-invalid': submitted && f3.telephone.errors }" />
            <div *ngIf="submitted && f3.telephone.errors" class="invalid-feedback">
                <div *ngIf="f3.telephone.errors.required"> Telephone est obligatoire </div>
            </div>
        </div>
        <!-- champs mot_de_passe avec une gestion de la validité -->
        <div class="form-group col-sm-12">
            <label>Mot de passe <span class="text-red">*</span> : </label>
            <input class="form-control" type="text" formControlName="mot_de_passe" placeholder="......."
                [ngClass]="{ 'is-invalid': submitted && f3.mot_de_passe.errors }" />
            <div *ngIf="submitted && f3.mot_de_passe.errors" class="invalid-feedback">
                <div *ngIf="f3.mot_de_passe.errors.required"> mot de passe est obligatoire </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
            <button type="button" (click)="onSubmit_add_utilisateur()" [disabled]="loading_add_utilisateur"
                class="btn btn-primary">{{loading_add_utilisateur?"En cours ...":"Valider"}}</button>
        </div>
    </form>
</div>
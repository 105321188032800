import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../service/api/api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangerMotDePasseComponent } from '../../changer-mot-de-passe/changer-mot-de-passe.component';
@Component({
    selector: 'app-list-login',
    templateUrl: './list-login.component.html',
    styleUrls: ['./list-login.component.css']
})
export class ListLoginComponent {
    reactiveForm_login_login !: FormGroup;
    submitted: boolean = false
    loading_login_login: boolean = false
    showHidePassword: any;
    i: number = 0
    constructor(private formBuilder: FormBuilder, public api: ApiService, private router: Router, public activeModal: NgbActiveModal, private modalService: NgbModal) { }

    ngOnInit(): void {
        this.init_form()
    }
    init_form() {
        this.reactiveForm_login_login = this.formBuilder.group({
            login: ["", Validators.required],
            pwd: ["", Validators.required]
        });
    }

    // acces facile au champs de votre formulaire
    get f(): any { return this.reactiveForm_login_login.controls; }
    // validation du formulaire
    onSubmit_login_login() {
        this.submitted = true;
        console.log(this.reactiveForm_login_login.value)
        // stop here if form is invalid
        if (this.reactiveForm_login_login.invalid) {
            return;
        }
        var login = this.reactiveForm_login_login.value
        this.login_login(login)
    }
    // vider le formulaire
    onReset_login_login() {
        this.submitted = false;
        this.reactiveForm_login_login.reset();
    }
    login_login(login: any) {
        this.loading_login_login = true;
        this.api.taf_post_login("taf_auth/auth", login, async (reponse: any) => {
            if (reponse.status) {
                console.log("Opération effectuée avec succés sur la table login. Réponse= ", reponse);
                this.onReset_login_login()
                await this.api.save_on_local_storage("token", reponse.data)
                this.api.network.token = reponse.data
                this.activeModal.close()
                await this.api.update_data_from_token()
                this.api.utilisateur_connecte = reponse.dans
                this.api.Swal_success("Connexion éffectuée avec succès")
                this.router.navigate(["/home"])
            } else {
                this.i++
                if (this.i == 3) {
                    this.openModal_changer_mot_de_passe({
                        email : this.reactiveForm_login_login.value.login
                    })
                }
                console.log("L'opération sur la table login a échoué. Réponse= ", reponse);
                this.api.Swal_error("Identifiant ou mot de passe incorrect")
            }
            this.loading_login_login = false;
        }, (error: any) => {
            this.loading_login_login = false;
        })
    }
    rediriger() {
        let id_privilege = parseInt(this.api.token.token_decoded.taf_data.id_role)
        if (id_privilege == 3) {
            this.router.navigate(["/public/accueil"])
        }
        else {
            this.router.navigate(["/home/produit"])
        }
    }
    showPassword() {
        this.showHidePassword = document.getElementById('passwordInput');
        if (this.showHidePassword.type == 'text') {
            this.showHidePassword.type = 'password';
        } else {
            this.showHidePassword.type = 'text';
        }
    }
    openModal_changer_mot_de_passe(email: any) {
        let options: any = {
            centered: true,
            scrollable: true,
            size: "xl"//'sm' | 'lg' | 'xl' | string
        }
        const modalRef = this.modalService.open(ChangerMotDePasseComponent, { ...options, backdrop: 'static', })
        modalRef.componentInstance.email = email;
        modalRef.result.then((result: any) => {
            console.log('Modal closed with:', result);
            if (result?.status) {
                this.i = 0
            } else {

            }
        })
    }
}

<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm">
    <div class="container">
        <a class="navbar-brand fw-bold fs-3" href="#">BKmarket</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <div class="mx-auto w-50">
                <div class="form-floating">
                    <input type="text" class="form-control rounded-pill" id="floatingInput" placeholder="Rechercher">
                    <label for="floatingInput">Rechercher</label>
                </div>
            </div>
            <ul class="navbar-nav ms-auto d-flex align-items-center">
                <ng-container *ngIf="api.isUserConnected && api.token?.user_connected?.id_role == 3">
                    <li [routerLink]="['/public/mes_commandes']" class="nav-item mx-2 fs-5">
                        <a class="nav-link">Mes Commandes</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="api.isUserConnected && api.token?.user_connected?.id_role != 3">
                    <li class="nav-item mx-2 fs-5">
                        <a class="nav-link" [routerLink]="['/home']">Tableau de bord</a>
                    </li>
                </ng-container>
                <li [routerLink]="['./panier']" class="nav-item position-relative d-inline-block mx-2">
                    <i class='bx bx-cart fs-2 ms-2'></i>
                    <span *ngIf="api.nombreProduitPanier != 0"
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {{ api.nombreProduitPanier }}
                    </span>
                </li>
                <li class="nav-item dropdown ms-2">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLink" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="bx bx-user-circle fs-2"></i>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ng-container *ngIf="api.isUserConnected">
                            <li>
                                <a class="dropdown-item" [routerLink]="['/mon-profil']">
                                    <i class="bx bx-user me-2"></i>
                                    <span class="align-middle">Mon profil</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" [routerLink]="['/public/deconnexion']">
                                    <i class="bx bx-power-off me-2"></i>
                                    <span class="align-middle">Déconnexion</span>
                                </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!api.isUserConnected">
                            <li><a class="dropdown-item" (click)="openModal_se_connecter()">Se connecter</a></li>
                            <li><a class="dropdown-item" (click)="openModal_inscription()">S'inscrire</a></li>
                        </ng-container>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="contenu pt-4">
    <router-outlet></router-outlet>
</div>

<footer class="bg-light text-dark py-5 mt-5">
    <div class="container">
        <div class="row">
            <!-- About Us -->
            <div class="col-lg-3 col-md-6 mb-4">
                <h5 class="text-uppercase fw-bold">À propos de nous</h5>
                <p>BKmarket est votre boutique en ligne de confiance pour des produits de qualité à des prix
                    compétitifs.</p>
            </div>

            <!-- Quick Links -->
            <div class="col-lg-3 col-md-6 mb-4">
                <h5 class="text-uppercase fw-bold">Liens rapides</h5>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-dark">Accueil</a></li>
                    <li><a href="#" class="text-dark">Catégories</a></li>
                    <li><a href="#" class="text-dark">Mes commandes</a></li>
                    <li><a href="#" class="text-dark">Contactez-nous</a></li>
                </ul>
            </div>

            <!-- Customer Service -->
            <div class="col-lg-3 col-md-6 mb-4">
                <h5 class="text-uppercase fw-bold">Service client</h5>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-dark">FAQ</a></li>
                    <li><a href="#" class="text-dark">Retours</a></li>
                    <li><a href="#" class="text-dark">Livraison</a></li>
                    <li><a href="#" class="text-dark">Politique de confidentialité</a></li>
                </ul>
            </div>

            <!-- Follow Us -->
            <div class="col-lg-3 col-md-6 mb-4">
                <h5 class="text-uppercase fw-bold">Suivez-nous</h5>
                <a href="#" class="text-dark me-3"><i class='bx bxl-facebook fs-3'></i></a>
                <a href="#" class="text-dark me-3"><i class='bx bxl-twitter fs-3'></i></a>
                <a href="#" class="text-dark me-3"><i class='bx bxl-instagram fs-3'></i></a>
                <a href="#" class="text-dark"><i class='bx bxl-linkedin fs-3'></i></a>
            </div>
        </div>
        <hr class="my-4">
        <div class="row">
            <div class="col text-center">
                <h6 class="text-uppercase fw-bold">Modes de paiement</h6>
                <i class='bx bxl-visa fs-2 me-2'></i>
                <i class='bx bxl-mastercard fs-2 me-2'></i>
                <i class='bx bxl-paypal fs-2 me-2'></i>
                <i class='bx bxl-apple fs-2'></i>
            </div>
        </div>
        <!-- Copyright -->
        <div class="text-center mt-3">
            <span class="fs-5">BKmarket©2024. Tous droits réservés.</span>
        </div>
    </div>
</footer>
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../../service/api/api.service';

@Component({
  selector: 'app-details-panier',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule],
  templateUrl: './details-panier.component.html',
  styleUrl: './details-panier.component.css'
})
export class DetailsPanierComponent {
  public quantite = 1;
  public panier: any = [];
  public nombreArticles = 0;
  public sommeArticles = 0;
  loading_add_commande: boolean = false
  adresseLivraison: any = ""
  numero_telephone: any = ""
  public nombreProduitPanier = JSON.parse(localStorage.getItem('panier') ?? '[]').length;

  // Methodes
  constructor(public api: ApiService, private router: Router) {

  }

  ngOnInit(): void {
    console.log("this.api.token = ", this.api.token)
    this.panier = this.api.getFromPanier();
    console.log("panier", this.panier)
    this.calculateTotal()
    this.totalArticles();
    this.nombreProduitPanier = JSON.parse(localStorage.getItem('panier') ?? '[]').length;
  }
  upOrDownQuantity(action: 'up' | 'down', produitId: any) {
    this.panier = this.panier.map((item: any) => {
      if (item.produit.id_produit === produitId) {
        if (action === 'up') {
          item.quantitePanier += 1;
        } else if (action === 'down' && item.quantitePanier > 1) {
          item.quantitePanier -= 1;
        }
      }
      return item;
    });
    localStorage.setItem('panier', JSON.stringify(this.panier));
    this.totalArticles();
    this.calculateTotal();
    this.refresh(this.panier.length);
    this.api.nombreProduitPanier = this.panier.length;
  }
  totalArticles() {
    this.nombreArticles = 0;
    this.sommeArticles = 0;
    let panierProduit = this.api.getFromPanier();
    panierProduit.forEach((element: any) => {
      this.nombreArticles += element.quantitePanier;
      this.sommeArticles += element.quantitePanier * element.produit.prix;
    });

  }

  deleteFromPanier(id: any) {
    let tab: any = [];
    tab = this.api.getFromPanier();
    tab.forEach((element: any, index: any) => {
      if (element.produit.id_produit == id) {
        tab.splice(index, 1);
      }
    });
    localStorage.setItem('panier', JSON.stringify(tab));
    this.panier = this.api.getFromPanier();
    this.totalArticles();
    this.calculateTotal()
    this.refresh(JSON.parse(localStorage.getItem('panier') ?? '[]').length);
    this.api.nombreProduitPanier = JSON.parse(localStorage.getItem('panier') ?? '[]').length
  }
  public refresh(number: any) {
    this.api.setRefresh(number);
  }
  calculateTotal() {
    this.sommeArticles = this.panier.reduce((total: any, item: any) => {
      return total + (item.produit.prix * item.quantitePanier);
    }, 0);
  }
  isPatternValid(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }

  verifier_connexion() {//Validators.pattern(/^\+?\d{9,15}$/)
    if (this.numero_telephone == "") {
      this.api.Swal_error("Le numéro de téléphone est obligatoire")
      return
    } else if (!this.isPatternValid(this.numero_telephone, /^\+?\d{9,15}$/)) {
      this.api.Swal_error("Le numéro de téléphone est incorrect")
      return
    } else if (this.adresseLivraison == "") {
      this.api.Swal_error("L'adresse est obligatoire")
      return
    }
    // if (!this.api.isUserConnected) {
    //   this.api.Swal_info("Veuillez d'abord vous connecter")
    // }
    // else {
    let commande: any = {
      adresse_livraison: this.adresseLivraison,
      numero_telephone: this.numero_telephone,
      // id_utilisateur: this.api.token?.user_connected.id_utilisateur,
    }
    commande.les_details = this.panier.map((one: any) => {
      let item: any = {
        id_produit: one.produit.id_produit,
        quantite: one.quantitePanier,
        prix_unitaire: +one.produit.prix
      }
      return item
    })
    console.log("commande", commande)
    this.add_commande(commande)
    // }
  }
  add_commande(commande: any) {
    this.loading_add_commande = true;
    this.api.taf_post("commande/add", commande, (reponse: any) => {
      if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table commande. Réponse= ", reponse);
        this.api.Swal_success("Commande ajoutée avec succés")
        this.router.navigate(['/public']);
        this.api.clearPanier()
      } else {
        console.log("L\'opération sur la table commande a échoué. Réponse= ", reponse);
        this.api.Swal_error("L'opération a echoué")
      }
      this.loading_add_commande = false;
    }, (error: any) => {
      this.loading_add_commande = false;
    })
  }
}
